.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 60px;
  background: #FFFFFF;
  border: 1px solid #CACACA;
  border-radius: 5px;
  line-height: 25px;
  height: 35px;
  width: 100% !important;
  max-width: 100%;
  outline: none;
  font-family: 'Trebuchet MS';
}



.react-tel-input .form-control:focus {
  background-color: #fff;
  border-color: #83BE00;
  ;
  outline: 0;
  box-shadow: #83BE00;
}

html,
body {
  padding-left: 0px !important;
  padding-right: 0px !important;
  min-width: 100%;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  font-family: 'Trebuchet MS';
}

.bg-img {
  background-image: url('../public/assets/img/bg.png');
  display: flex !important;
  flex-direction: column;
  background-size: cover;
  min-height: 100vh;
}

.bg-content {
  margin-left: 10%;
  margin-right: 20%;
  font-family: 'Trebuchet MS';
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
  font-family: 'Trebuchet MS';
}

.h2hr {
  margin-top: 100px !important;
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #6c757d;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.h2hrspan {
  background: #fff;
  padding: 0 10px;
}

a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}

@media (max-width: 700px) {
  body {
    margin: 20px;
    padding: 20px;
  }
}

iframe {
  display: none;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: -20px;
}